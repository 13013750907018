<template>
  <div class="fit flex items-center justify-center">

    <div v-if="loading">
      <div class="text-h4 text-weight-bold text-center">
        {{ $t('title.integrateZoom') }}
      </div>
      <div class="q-py-xl flex items-center justify-center">
        <q-img
          :src="require(`assets/zoom_animation.svg`)"
          width="500px"
        />
      </div>
      <div class="text-14-400 text-grey text-center">
        {{ $t('text.pleaseWaitIntegration') }}
      </div>
    </div>

    <div
      v-else
      class="column justify-center"
    >
      <div class="flex justify-center items-center">
        <q-img
          src="~/assets/check_ring.svg"
          width="120px"
        />
      </div>
      <div class="text-16-400 text-center">
        {{ $t('text.integrationCompletedSuccessfully') }}
      </div>
    </div>

  </div>
</template>

<script>
import handleError from 'common/helpers/handleError';
import { mapActions } from 'vuex';

export default {
  name: 'ZoomIntegrationPage',
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.create();
  },
  methods: {
    ...mapActions('dashboard', ['createWebinar']),
    async create() {
      try {
        this.loading = true;
        const state = [...this.$route.query.state.matchAll(/(.*?)=(.*?);/g)];
        const title = state.find((row) => row[1] === 'title')[2];
        const path = state.find((row) => row[1] === 'from')[2];

        await this.createWebinar({
          title,
          meta: {
            type: ENUMS.WEBINAR_TYPES.ZOOM,
            code: this.$route.query.code,
          },
        });
        this.loading = false;

        setTimeout(() => {
          this.$router.push({ path });
        }, 2000);
      } catch (error) {
        handleError(error);
      }
    },
  },
};
</script>
